import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { SettingsService } from '@app/core/shared/settings/settings.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { MonitoringService } from '@app/shared/services/monitoring.service';
import * as signalR from '@microsoft/signalr';
import * as signalRMsgPack from '@microsoft/signalr-protocol-msgpack';
import { throttle } from 'lodash-es';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { SignalRServices } from './shared/clients/signalr-status.client';
/* eslint-disable @typescript-eslint/naming-convention */
const COLORS = {
    RECONNECTING: 'fuchsia',
    RECONNECTED: 'deepskyblue',
    CLOSE: 'crimson',
    LOCK: 'lime',
};
/* eslint-enable @typescript-eslint/naming-convention */

export type SignalRHandler = (...args: any[]) => void;
export interface SignalRConnectionSettings {
    accessToken: string;
    url: string;
}
export interface HubConnection {
    connectionId: string;
    socket: string;
    hubConnection: signalR.HubConnection;
    subscriptions: {
        [groupName: string]: {
            [eventName: string]: SignalRHandler;
        };
    };
    logSubscriptionDetails: (...args: any[]) => void;
}

@Injectable({ providedIn: 'root' })
export class SignalRHubService {
    currentWarehouse: Warehouse;
    acquireLockFailed: () => void;
    webLockRelease: any;
    currentToken: any[] = [];

    private readonly http = inject(HttpClient);
    private readonly appLoadService = inject(AppLoadService);
    private readonly monitoringService = inject(MonitoringService);
    private readonly settingsService = inject(SettingsService);

    constructor() {
        this.appLoadService.getCurrentWarehouse.pipe(take(1)).subscribe((res) => {
            this.currentWarehouse = res;
        });
    }

    async connect(
        negotiateWith: string,
        onReconnected?: () => Promise<void>,
        onDisconnected?: () => Promise<void>,
        onReconnecting?: () => Promise<void>,
        onAcquireLockFailed?: () => Promise<void>,
        token: string = '',
    ): Promise<HubConnection> {
        const socket = negotiateWith.toLowerCase();// url.split('/').pop();
        const url = this.settingsService.signalRSettings.tokenBaseUrl + socket;

        let connection: HubConnection = null;
        const loggingLevel = localStorage.getItem('signalrLoggingLevel') || signalR.LogLevel.None;
        const options = {
            //transport: signalR.HttpTransportType.WebSockets,
            accessTokenFactory: async () => {
                return new Promise<string>((resolve) => {
                    resolve(this.getCurrentToken(socket));
                });
            }
        }
        try {
            let hubConnectionBuilder = new signalR.HubConnectionBuilder()
                .withUrl(url, options)
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: (retryContext) => {
                        onReconnecting?.();
                        const elapsed = retryContext.elapsedMilliseconds;
                        if (elapsed > 60000) {
                            onDisconnected?.();
                        }
                        return elapsed <= 10000 ? 15000 : elapsed <= 20000 ? 30000 : 60000;
                    },
                })
                .configureLogging(loggingLevel);

            const signalRProtocol = localStorage.getItem('signalrprotocol');
            if (signalRProtocol !== '1') {
                console.log('messagepack active');
                hubConnectionBuilder = hubConnectionBuilder.withHubProtocol(new signalRMsgPack.MessagePackHubProtocol());
            }
            // Signal R Hub Connection
            const hubConnection = hubConnectionBuilder.build();

            hubConnection.onreconnecting((error) => {
                // tslint:disable-next-line:max-line-length
                //console.log(`%c Reconnecting to ${hubConnection.baseUrl} due to "${error}"`, `color: ${COLORS.RECONNECTING}`);
                console.log(`%c Reconnecting to ${socket} due to "${error}"`, `color: ${COLORS.RECONNECTING}`);
            });

            hubConnection.onreconnected((id) => {
                //console.log(`%c Reconnected to ${hubConnection.baseUrl}`, `color: ${COLORS.RECONNECTED}`);
                console.log(`%c Reconnected to ${socket}`, `color: ${COLORS.RECONNECTED}`);
                (onReconnected?.() ?? Promise.resolve()).then(() => {
                    connectionId = id;
                    Object.keys(connection.subscriptions).forEach((group) => {
                        if (socket.toLowerCase() === SignalRServices.WAREHOUSE_VARIABLE_STATUS.toLocaleLowerCase()) { //HARDCODE!!!! //DEMO JoinVariablesMapGroup
                            // const regExpMap = new RegExp('[A-Z0-9]+-(CUSTOMS-)?[A-Z0-9.]+') //DEMO JoinVariablesMapGroup
                            // console.log(groupName.match(regExpMap), ' HIZO match') //DEMO JoinVariablesMapGroup
                            let joinTo = 'JoinGroup' //DEMO JoinVariablesMapGroup
                            // //console.log(regExpMap.test(groupName), ' HIZO match') //DEMO JoinVariablesMapGroup
                            // if (regExpMap.test(groupName)) { a = 'JoinVariablesMapGroup'; } //DEMO JoinVariablesMapGroup
                            if (!group.includes('requestId')) { joinTo = 'JoinVariablesMapGroup'; } //DEMO JoinVariablesMapGroup
                            hubConnection.invoke(joinTo, group); //DEMO JoinVariablesMapGroup
                        } else {
                            hubConnection.invoke('JoinGroup', group);
                        }
                    });
                });
            });

            hubConnection.onclose((error) => {
                onDisconnected?.();
                this.monitoringService.logMetric('Auwa.Web.SignalR.SocketDisconnected', 1, null, null, null, {
                    warehouseId: this.currentWarehouse.warehouse,
                    socket,
                    visibilityState: document.visibilityState,
                });
                // tslint:disable-next-line:max-line-length
                console.log(`%c Disconnected to ${socket} due to ${error || 'navigation'}`, `color: ${COLORS.CLOSE}`);
                // Release tab freezing lock
                this.webLockRelease?.();
            });

            //Start the connection
            await hubConnection.start();
            if (!this.acquireLockFailed) {
                this.acquireLockFailed = onAcquireLockFailed;
            }
            let connectionId = hubConnection.connectionId;
            connection = {
                connectionId,
                socket,
                hubConnection,
                subscriptions: {},
                logSubscriptionDetails: throttle(this.logSubscriptionCount, 1000, { trailing: true }),
            };
        } catch (error) {
            console.warn(`Error connecting to ${url}: "${error}"`);
            return new Promise((resolve) => {
                setTimeout(() => resolve(this.connect(socket, onReconnected, onDisconnected, onReconnecting, onAcquireLockFailed)), 5000);
            });
        }
        console.log(`Connected to ${socket}`);
        return connection;
    }

    async subscribe$(
        connection: HubConnection,
        groupName: string,
        supportedEvents: Array<string>,
        notifyEvent: (groupName: string, eventName: string, eventGroup: any[]) => void,
    ): Promise<void> {
        try {
            if (connection && connection.hubConnection.state === signalR.HubConnectionState.Connected) {
                supportedEvents.forEach((eventName) => {
                    const eventHandler = (eventGroup) => notifyEvent(groupName, eventName, eventGroup);
                    connection.hubConnection.on(eventName, eventHandler);
                    connection.subscriptions[groupName] = {
                        ...(connection.subscriptions[groupName] || {}),
                        [eventName]: eventHandler,
                    };
                });
                //if (socket.toLowerCase() === SignalRServices.WAREHOUSE_VARIABLE_STATUS.toLocaleLowerCase())
                if (connection.socket.toLocaleLowerCase() === SignalRServices.WAREHOUSE_VARIABLE_STATUS.toLocaleLowerCase()) { //HARDCODE!!!! //DEMO JoinVariablesMapGroup
                    // const regExpMap = new RegExp('[A-Z0-9]+-(CUSTOMS-)?[A-Z0-9.]+')//DEMO JoinVariablesMapGroup
                    // console.log(groupName.match(regExpMap), ' HIZO match')//DEMO JoinVariablesMapGroup
                    let joinTo = 'JoinGroup'//DEMO JoinVariablesMapGroup
                    // //console.log(regExpMap.test(groupName), ' HIZO match')//DEMO JoinVariablesMapGroup
                    // if (regExpMap.test(groupName)) { a = 'JoinVariablesMapGroup'; }//DEMO JoinVariablesMapGroup
                    if (!groupName.includes('requestId')) { joinTo = 'JoinVariablesMapGroup'; }//DEMO JoinVariablesMapGroup

                    await connection.hubConnection.invoke(joinTo, groupName); //DEMO JoinVariablesMapGroup
                } else {
                    await connection.hubConnection.invoke('JoinGroup', groupName);
                }
                connection.logSubscriptionDetails(connection);
            }
        } catch (err) {
            console.error(err, groupName, supportedEvents);
        }
    }

    async unsubscribe$(connection: HubConnection, groupName: string, supportedEvents: string[]) {
        try {
            if (connection && connection.hubConnection.state === signalR.HubConnectionState.Connected && connection.subscriptions[groupName]) {
                supportedEvents.forEach((eventName) => {
                    const eventHandler = connection.subscriptions[groupName][eventName];
                    connection.hubConnection.off(eventName, eventHandler);
                    connection.subscriptions[groupName][eventName] = null;
                    delete connection.subscriptions[groupName][eventName];
                });
                if (!Object.keys(connection.subscriptions[groupName] || {}).length) {
                    if (connection.socket.toLocaleLowerCase() === SignalRServices.WAREHOUSE_VARIABLE_STATUS.toLocaleLowerCase()) { //HARDCODE!!!! //DEMO JoinVariablesMapGroup
                        let leaveFrom = 'LeaveGroup' //DEMO JoinVariablesMapGroup
                        // //console.log(regExpMap.test(groupName), ' HIZO match') //DEMO JoinVariablesMapGroup
                        // if (regExpMap.test(groupName)) { a = 'JoinVariablesMapGroup'; } //DEMO JoinVariablesMapGroup
                        if (!groupName.includes('requestId')) { leaveFrom = 'LeaveVariableMapGroup'; } //DEMO JoinVariablesMapGroup

                        await connection.hubConnection.invoke(leaveFrom, groupName); //DEMO JoinVariablesMapGroup
                    } else {
                        await connection.hubConnection.invoke('LeaveGroup', groupName);
                    }
                    delete connection.subscriptions[groupName];
                }
                connection.logSubscriptionDetails(connection);
            }
        } catch (err) {
            console.error('SignalR unsubscribe$ => ' + err + ' - groupName = ' + groupName);
        }
    }

    logSubscriptionCount(connection: HubConnection) {
        if (!localStorage.getItem('showLogSubscriptions')) return;
        console.log(`${connection.socket}:: Active subscriptions for ${connection.socket}: ${Object.keys(connection.subscriptions).length}`);
        Object.keys(connection.subscriptions).forEach((groupName) => {
            console.log(`     ${groupName}:: Listening to ${Object.keys(connection.subscriptions[groupName]).join(', ')}`);
        });
    }

    disconnect(connection: HubConnection): void {
        try {
            const { hubConnection } = connection;
            if (hubConnection) {
                if (hubConnection.state === signalR.HubConnectionState.Connected) {
                    Promise.all(
                        Object.keys(connection.subscriptions).reduce((acc, eventName) => {
                            const groupNames = Object.keys(connection.subscriptions);
                            // tslint:disable-next-line:max-line-length
                            const leaveGroup = (groupName) =>
                                hubConnection.invoke('LeaveGroup', groupName).catch((error) => console.warn('Unable to LeaveGroup.', error.message));
                            return [...acc, ...groupNames.map(leaveGroup)];
                        }, []),
                    )
                        .then(() => {
                            Object.keys(connection.subscriptions).forEach(hubConnection.off);
                            hubConnection.stop();
                        })
                        .catch((error) => console.warn('Unable to disconnect', error.message));
                } else {
                    hubConnection.stop();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    acquireWebLock(connection: HubConnection) {
        try {
            // Lock to avoid tab freezing
            // https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-7.0&tabs=visual-studio#bsleep
            if (navigator && navigator.locks && navigator.locks.request) {
                const promise = new Promise((res) => (this.webLockRelease = res));
                navigator.locks.request(`${connection.hubConnection.baseUrl}_lock`, { mode: 'shared' }, () => promise);
                console.log(`%c Lock ${connection.hubConnection.baseUrl} adquired to avoid tab freezing`, `color: ${COLORS.LOCK}`);
            } else {
                this.acquireLockFailed?.();
            }
        } catch (error) {
            console.error(error);
        }
    }

    signalRNegotiateWithServer(hub: string) {
        //const signalRNegotiateEndPoint = `https://auwa-fap-weu1-dev-002-signalr-manager.azurewebsites.net/api/${hub}/Negotiate`;
        const baseUrl = this.settingsService.signalRSettings.baseUrl;
        const signalRNegotiateEndPoint = `${baseUrl}/${hub}/Negotiate`;
        return this.http.post(signalRNegotiateEndPoint, null).pipe(take(1));
    }

    setCurrentToken(socket, token) {
        try {
            this.currentToken[socket] = token;
        } catch (error) {
            console.log(`%c error => ${error}`, `background: red; color: white`);
        }

    }

    async getCurrentToken(socket) {
        let needNewToken = false;
        const tokenFounded = this.currentToken[socket];
        if (tokenFounded) {
            try {
                const tokenAtobed = JSON.parse(atob(tokenFounded?.split('.')[1]))
                if (Math.floor(new Date().getTime() / 1000) >= tokenAtobed['exp']) {
                    needNewToken = true;
                } else {
                    return tokenFounded;
                }
            } catch (error) {
                console.log(`%c error => ${error}`, `background: red; color: `);
            }
        } else {
            needNewToken = true;
        }
        if (needNewToken) {
            const signalRNegotiateActualUrlToken = await this.getSignalRUrlAndAccessToken(socket);
            const newToken = signalRNegotiateActualUrlToken?.accessToken ?? '';
            this.setCurrentToken(socket, newToken)
            return newToken
        } else {
            return ''
        }
    }

    async getSignalRUrlAndAccessToken(hub) {
        const signalRUrlAndAccessToken: SignalRConnectionSettings = await lastValueFrom(this
            .signalRNegotiateWithServer(hub))
            .then((negotiateCredentials) => {
                return { accessToken: negotiateCredentials['AccessToken'], url: negotiateCredentials['Url'] };
            });
        return signalRUrlAndAccessToken;
    }
}

import { Alert } from '@alerts/shared/alert';
import { AlertsActiveCount, AlertsActiveStatus } from '../events/alert-status';
import { AlertsCount } from '@alerts/shared/alerts';

const alertsActiveStatusMapper = (alertActiveStatus: AlertsActiveStatus): Alert => {
    return {
        floorId: alertActiveStatus.id?.split('-')[1] || alertActiveStatus.floorid, //
        areaId: alertActiveStatus.id?.split('-')[2] || alertActiveStatus.areaid, //
        zoneId: alertActiveStatus.id?.split('-')[3] || alertActiveStatus.zoneid, //
        lineId: alertActiveStatus.id?.split('-')[4] || alertActiveStatus.lineid, //
        equipmentId: alertActiveStatus.id?.split('-')[6] || alertActiveStatus.equipmentId, //
        equipmentType: alertActiveStatus.id?.split('-')[5] || alertActiveStatus.equipmentType, //
        state: alertActiveStatus.state, //
        variableName: alertActiveStatus.variableName,
        description: alertActiveStatus.variableName, //
        priority: alertActiveStatus.priority, //
        sourceTimeStamp: alertActiveStatus.sourceTimeStamp.toString(),
        source: alertActiveStatus.source,
        serverts: alertActiveStatus.serverTimeStamp.toString(),
        alarmSourceTimeStamp: alertActiveStatus.sourceTimeStamp,
        closedTimeStamp: new Date(),
        alarmServerTimeStamp: alertActiveStatus.serverTimeStamp.toString(),
        isCritical: String(alertActiveStatus.id?.split('-')[5] || alertActiveStatus.equipmentType).toUpperCase() === 'EMERGENCY',
        realDescription: alertActiveStatus.variableName, //used to enable app-search in alerts active
    };
};

const initialAlertsActiveStatusMapper = (alertActiveStatus: AlertsActiveStatus): Alert => {
    return {
        floorId: alertActiveStatus.id?.split('-')[1] || alertActiveStatus.floorid, //
        areaId: alertActiveStatus.id?.split('-')[2] || alertActiveStatus.areaid, //
        zoneId: alertActiveStatus.id?.split('-')[3] || alertActiveStatus.zoneid, //
        lineId: alertActiveStatus.id?.split('-')[4] || alertActiveStatus.lineid, //
        equipmentId: alertActiveStatus.id?.split('-')[6] || alertActiveStatus.equipmentId, //
        equipmentType: alertActiveStatus.id?.split('-')[5] || alertActiveStatus.equipmentType, //
        state: alertActiveStatus.state, //
        variableName: alertActiveStatus.variableName,
        description: alertActiveStatus.variableName, //
        priority: alertActiveStatus.priority, //
        sourceTimeStamp: alertActiveStatus.sourceTimeStamp.toString(),
        source: alertActiveStatus.source,
        serverts: alertActiveStatus.serverTimeStamp.toString(),
        alarmSourceTimeStamp: alertActiveStatus.sourceTimeStamp,
        closedTimeStamp: new Date(),
        alarmServerTimeStamp: alertActiveStatus.sourceTimeStamp.toString(),
        isCritical: String(alertActiveStatus.id?.split('-')[5] || alertActiveStatus.equipmentType).toUpperCase() === 'EMERGENCY',
        realDescription: alertActiveStatus.variableName, //used to enable app-search in alerts active
    };
};

const alertsActiveCountMapper = (alertsActiveCount: AlertsActiveCount): AlertsCount => {
    return {
        warehouseId: alertsActiveCount.warehouseId,
        count: alertsActiveCount.newCount,
    };
};

export {
    alertsActiveStatusMapper,
    alertsActiveCountMapper,
    initialAlertsActiveStatusMapper
};

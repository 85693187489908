import { EquipmentStatusNotification, RawEquipmentStatusNotification, RawInitialEquipmentStatusNotification } from '../events/equipment-status';

const initialEquipmentStatusMapper = (equipmentStatusNotification: RawInitialEquipmentStatusNotification): EquipmentStatusNotification => {
    return {
        floorId: equipmentStatusNotification.id?.split('-')[1] || equipmentStatusNotification.floorid,
        areaId: equipmentStatusNotification.id?.split('-')[2] || equipmentStatusNotification.areaid,
        zoneId: equipmentStatusNotification.id?.split('-')[3] || equipmentStatusNotification.zoneid,
        lineId: equipmentStatusNotification.id?.split('-')[4] || equipmentStatusNotification.lineid,
        equipmentId: equipmentStatusNotification.id?.split('-')[6] || equipmentStatusNotification.equipmentId,
        //equipmentType: equipmentStatusNotification.equipmentId,
        equipmentType: equipmentStatusNotification.id?.split('-')[5] || equipmentStatusNotification.equipmentType,
        //equipmentId: equipmentStatusNotification.equipmentType,
        state: equipmentStatusNotification.state,
        variableName: equipmentStatusNotification.variableName?.replace(/^(FAILURE\.)/, ''),
        priority: equipmentStatusNotification.priority,
        timestamp: equipmentStatusNotification.timestamp,
        serverts: equipmentStatusNotification.serverts,
    };
};

const equipmentStatusMapper = (equipmentStatusNotification: RawEquipmentStatusNotification): EquipmentStatusNotification => {
    return {
        floorId: equipmentStatusNotification.id?.split('-')[1] || equipmentStatusNotification.floorid,
        areaId: equipmentStatusNotification.id?.split('-')[2] || equipmentStatusNotification.areaid,
        zoneId: equipmentStatusNotification.id?.split('-')[3] || equipmentStatusNotification.zoneid,
        lineId: equipmentStatusNotification.id?.split('-')[4] || equipmentStatusNotification.lineid,
        equipmentId: equipmentStatusNotification.id?.split('-')[6] || equipmentStatusNotification.equipmentId,
        //equipmentType: equipmentStatusNotification.equipmentId,
        equipmentType: equipmentStatusNotification.id?.split('-')[5] || equipmentStatusNotification.equipmentType,
        //equipmentId: equipmentStatusNotification.equipmentType,
        state: equipmentStatusNotification.state,
        variableName: equipmentStatusNotification.variableName?.replace(/^(FAILURE\.)/, ''),
        priority: equipmentStatusNotification.priority,
        timestamp: equipmentStatusNotification.timestamp,
        serverts: equipmentStatusNotification.serverts,
    };
};

export { equipmentStatusMapper, initialEquipmentStatusMapper };
